import { ComponentProps, useRef } from 'react';
import { useIframeMessages } from './common';
import { MFE, Page, getConfig } from './config';
import type { IFrame } from 'components/atoms/IFrame';

const useAnalyticsAdapter = (page: Page): ComponentProps<typeof IFrame> => {
  const ref = useRef<HTMLIFrameElement | null>(null);
  const config = getConfig(page as MFE, page as Page);
  const src = `${config.url}`;
  useIframeMessages(ref, 'Analytics', config.path);
  return {
    ref,
    basePath: src,
    src,
    title: config.title,
  };
};

export { useAnalyticsAdapter };
