import { useState, ReactElement, useCallback } from 'react';
import { SvgIcon, SvgNames } from '@sunrun/experience-ui-components';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled/macro';
import { useFlags } from 'flagsmith/react';
import { SplatUserType, isRetailUser } from '@sunrun/sales-experience-shared';
import sunrunLogo from 'assets/images/Logo.svg';
import sunrunLogoIcon from 'assets/images/SR Logo.svg';
import leftArrowIcon from 'assets/images/chevron-left-filled.svg';
import rightArrowIcon from 'assets/images/chevron-right-filled.svg';
import { NavButton } from 'components/molecules/NavButton';
import { LoadingIndicator } from 'components/atoms/LoadingSpinner';
import { Icon } from 'components/atoms/Icon';
import type { Path } from 'router';
import { maxWidth } from 'helpers/styleConstants';
import { useAuthAdapter } from 'adapters/auth';
import { getEnv } from 'helpers/env';
import { NotificationsButton } from 'components/molecules/NotificationsButton';
import { FeatureFlags } from 'fixtures/features';

type NavListItemProps = {
  href: Path;
  icon?: () => ReactElement;
  isActive?: boolean;
  isSideNavExtended: boolean;
  isMobile: boolean;
  onClick: () => void;
  text: string;
};

const NavListItem = ({
  href,
  icon,
  isSideNavExtended,
  isMobile,
  onClick,
  text,
}: NavListItemProps) => {
  const { pathname } = useLocation();

  return (
    <NavListItemContainer
      isSideNavExtended={isSideNavExtended}
      isMobile={isMobile}>
      <NavButton href={href} icon={icon} onClick={onClick}>
        {isSideNavExtended ? text : ''}
      </NavButton>
      {pathname.startsWith(href) && (
        <DotContainer isSideNavExtended={isSideNavExtended} isMobile={isMobile}>
          <div className="dot"></div>
        </DotContainer>
      )}
    </NavListItemContainer>
  );
};

type SideNavProps = {
  userType: SplatUserType | '';
};

const SideNav = ({ userType }: SideNavProps) => {
  const [isSideNavExtended, setSideNavExtended] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: maxWidth });
  const setSideNavCollapsed = useCallback(() => {
    setSideNavExtended(false);
  }, [setSideNavExtended]);
  const { isOktaToken } = useAuthAdapter();
  const isStaging = getEnv() === 'staging';

  const isEmpty = userType === '';
  const isDFS = userType === 'Field Sales';
  const isNIS = userType === 'Inside Sales';
  const isRetail = userType && isRetailUser(userType);
  const isStreet = userType === 'Direct to Home';
  const isFusion = ['Fusion Closer', 'Fusion Setter'].includes(userType);
  const isDFSisNISisRetail = (isDFS || isNIS || isRetail) && !isEmpty;
  const isStreetisFusion = (isStreet || isFusion) && !isEmpty;
  const flags = useFlags([
    FeatureFlags.SALES_METRIC_DASHBOARD,
    FeatureFlags.ONE_FUSION_SCHEDULING,
    FeatureFlags.AI_SALES_APP_ENABLED,
  ]);

  const showFusionScheduling =
    flags[FeatureFlags.ONE_FUSION_SCHEDULING]?.enabled && isFusion;

  return (
    <SideNavContainer isMobile={isMobile} isSideNavExtended={isSideNavExtended}>
      <SideNavLinks isMobile={isMobile}>
        <SideNavLogoLink
          isMobile={isMobile}
          isSideNavExtended={isSideNavExtended}>
          <NavButton
            href="/"
            icon={() =>
              isSideNavExtended || isMobile ? (
                <StyledSunrunIcon src={sunrunLogo} alt="Sunrun Logo" />
              ) : (
                <Icon
                  src={sunrunLogoIcon}
                  alt={'logo'}
                  size="small"
                  data-testId="sidenav-item-home"
                />
              )
            }
          />
        </SideNavLogoLink>
        <SideNavLinkGroup
          isMobile={isMobile}
          isSideNavExtended={isSideNavExtended}
          grow>
          <NavListItem
            href="/dashboard"
            icon={() => (
              <SvgIcon
                name={SvgNames.Home}
                width="25"
                height="25"
                color="white"
                data-testId="sidenav-item-dashboard"
              />
            )}
            isSideNavExtended={isSideNavExtended}
            isMobile={isMobile}
            onClick={setSideNavCollapsed}
            text="dashboard"
          />
          {flags[FeatureFlags.AI_SALES_APP_ENABLED]?.enabled && (
            <NavListItem
              href="/analytics"
              icon={() => (
                <SvgIcon
                  name={SvgNames.Lightning}
                  width="25"
                  height="25"
                  color="white"
                  data-testId="sidenav-item-dashboard"
                />
              )}
              isSideNavExtended={isSideNavExtended}
              isMobile={isMobile}
              onClick={setSideNavCollapsed}
              text="analytics"
            />
          )}
          {isRetail && (
            <NavListItem
              href="/newLeads/channel-selection"
              icon={() => (
                <SvgIcon
                  name={SvgNames.Dishwasher}
                  width="25"
                  height="25"
                  color="white"
                />
              )}
              isSideNavExtended={isSideNavExtended}
              isMobile={isMobile}
              onClick={setSideNavCollapsed}
              text="channel selection"
            />
          )}
          {(isDFSisNISisRetail || showFusionScheduling) && (
            <NavListItem
              href="/appointments"
              icon={() => (
                <SvgIcon
                  name={SvgNames.Appointments}
                  width="25"
                  height="25"
                  color="white"
                  data-testId="sidenav-item-appointments"
                />
              )}
              isSideNavExtended={isSideNavExtended}
              isMobile={isMobile}
              onClick={setSideNavCollapsed}
              text="appointments"
            />
          )}
          {isStreetisFusion && (
            <NavListItem
              href="/map"
              icon={() => (
                <SvgIcon
                  name={SvgNames.Pin}
                  width="25"
                  height="25"
                  color="white"
                  data-testId="sidenav-item-canvass"
                />
              )}
              isSideNavExtended={isSideNavExtended}
              isMobile={isMobile}
              onClick={setSideNavCollapsed}
              text="map"
            />
          )}
          <NavListItem
            href="/customers"
            icon={() => (
              <SvgIcon
                name={SvgNames.UsersSvg}
                width="25"
                height="25"
                color="white"
                data-testId="sidenav-item-prospects"
              />
            )}
            isSideNavExtended={isSideNavExtended}
            isMobile={isMobile}
            onClick={setSideNavCollapsed}
            text="customers"
          />
          {isStreetisFusion && (
            <NavListItem
              href="/performance"
              icon={() => (
                <SvgIcon
                  name={SvgNames.Performance}
                  width="25"
                  height="25"
                  color="white"
                />
              )}
              isSideNavExtended={isSideNavExtended}
              isMobile={isMobile}
              onClick={setSideNavCollapsed}
              text="performance"
            />
          )}
          {isStaging && isOktaToken && (
            <NavListItem
              href="/masquerade"
              icon={() => (
                <SvgIcon
                  name={SvgNames.Mask}
                  width="25"
                  height="25"
                  color="white"
                />
              )}
              isSideNavExtended={isSideNavExtended}
              isMobile={isMobile}
              onClick={setSideNavCollapsed}
              text="masquerade"
            />
          )}
          {flags[FeatureFlags.SALES_METRIC_DASHBOARD]?.enabled &&
            isStreetisFusion && (
              <NavListItem
                href="/metrics"
                icon={() => (
                  <SvgIcon
                    name={SvgNames.Runx}
                    width="47"
                    height="13"
                    color="white"
                  />
                )}
                isSideNavExtended={isSideNavExtended}
                isMobile={isMobile}
                onClick={setSideNavCollapsed}
                text="metrics"
                data-testid="sales-experience--SideNav--salesMetrics--navItem--button"
              />
            )}
          {isEmpty && <StyledLoadingSpinner />}
        </SideNavLinkGroup>
        <Line isMobile={isMobile} isSideNavExtended={isSideNavExtended} />
        <SideNavLinkGroup
          isMobile={isMobile}
          isSideNavExtended={isSideNavExtended}>
          <NavListItem
            href="/notifications"
            icon={() => <NotificationsButton />}
            isSideNavExtended={isSideNavExtended}
            isMobile={isMobile}
            onClick={setSideNavCollapsed}
            text="notifications"
          />
          <NavListItem
            href="/support"
            icon={() => (
              <SvgIcon
                name={SvgNames.Help}
                width="25"
                height="25"
                color="white"
                data-testId="sidenav-item-support"
              />
            )}
            isSideNavExtended={isSideNavExtended}
            isMobile={isMobile}
            onClick={setSideNavCollapsed}
            text="support"
          />
          <NavListItem
            href="/profile"
            icon={() => (
              <SvgIcon
                name={SvgNames.UserSvg}
                width="25"
                height="25"
                color="white"
                data-testId="sidenav-item-profile"
              />
            )}
            isSideNavExtended={isSideNavExtended}
            isMobile={isMobile}
            onClick={setSideNavCollapsed}
            text="profile"
          />
        </SideNavLinkGroup>
      </SideNavLinks>
      {!isMobile && (
        <CollapseButtonContainer isSideNavExtended={isSideNavExtended}>
          {!isSideNavExtended && (
            <div
              data-testid="expand-nav"
              onClick={() => setSideNavExtended(true)}>
              <Icon alt="dashboard" size={'small'} src={rightArrowIcon} />
            </div>
          )}
          {isSideNavExtended && (
            <div onClick={() => setSideNavExtended(false)}>
              <Icon alt="dashboard" size={'small'} src={leftArrowIcon} />
            </div>
          )}
        </CollapseButtonContainer>
      )}
      {isMobile && !isSideNavExtended && (
        <BurgerContainer
          onClick={() => {
            setSideNavExtended(!isSideNavExtended);
          }}>
          <SvgIcon name={SvgNames.Menu} width="35" height="35" color="white" />
        </BurgerContainer>
      )}
      {isMobile && isSideNavExtended && (
        <CloseContainer
          onClick={() => {
            setSideNavExtended(!isSideNavExtended);
          }}>
          <SvgIcon name={SvgNames.Close} width="25" height="25" color="white" />
        </CloseContainer>
      )}
    </SideNavContainer>
  );
};

const calcSideNavWidth = (isSideNavExtended: boolean, isMobile: boolean) => {
  if (!isMobile && !isSideNavExtended) return '80px';
  else if (!isMobile && isSideNavExtended) return '200px';
  else if (isMobile && isSideNavExtended) return '100%';
};

const SideNavContainer = styled.nav<{
  isSideNavExtended: boolean;
  isMobile: boolean;
}>`
  display: ${(props) =>
    !props.isSideNavExtended && props.isMobile ? 'flex' : 'static'};
  justify-content: space-between;
  align-items: center;
  position: ${(props) =>
    (props.isSideNavExtended && props.isMobile) || !props.isMobile
      ? 'fixed'
      : 'relative'};
  padding: ${(props) => (props.isMobile ? '20px' : '20px 20px 50px 20px')};
  width: ${(props) => {
    return calcSideNavWidth(props.isSideNavExtended, props.isMobile);
  }};
  height: ${(props) =>
    !props.isSideNavExtended && props.isMobile ? 'auto' : '100vh'};
  box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.05);
  background-color: black;
  transition: 0.3s;
  z-index: 100;
`;

const SideNavLogoLink = styled.div<{
  isSideNavExtended: boolean;
  isMobile: boolean;
}>`
  padding: ${(props) =>
    props.isSideNavExtended || !props.isMobile
      ? '0px 0px 50px 4px'
      : '0px 9px 0px 0px'};
`;

const StyledSunrunIcon = styled.img`
  height: 30px;
  padding-top: 5px;
  margin-bottom: 5px;
`;

const SideNavLinks = styled.div<{ isMobile: boolean }>`
  display: flex;
  height: ${(props) => (props.isMobile ? 'auto' : '100%')};
  flex-direction: column;
  overflow: scroll;
  min-width: 47px;

  // allow scrolling but hide scrollbar:
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const SideNavLinkGroup = styled.div<{
  isMobile: boolean;
  isSideNavExtended: boolean;
  grow?: boolean;
}>`
  display: ${(props) =>
    props.isMobile && !props.isSideNavExtended ? `none` : `flex`};
  transition: 0.3s;
  flex-direction: column;
  flex-grow: ${(props) => (props.grow ? '1' : '0')};
  align-items: ${(props) =>
    props.isSideNavExtended ? 'flex-start' : 'center'};
`;

const NavListItemContainer = styled.div<{
  isSideNavExtended: boolean;
  isMobile: boolean;
}>`
  position: relative;
  transition: 0.3s;
  padding: 10px 0px 20px 0px;
  width: ${(props) => (props.isSideNavExtended ? '100%' : 'auto')};
  display: flex;
  flex-direction: ${(props) => (props.isSideNavExtended ? 'row' : 'column')};
  align-items: center;
  justify-content: ${(props) =>
    props.isMobile ? 'flex-start' : 'space-between'};
  gap: ${(props) => (!props.isSideNavExtended || props.isMobile ? '15px' : 0)};
`;

const DotContainer = styled.div<{
  isSideNavExtended: boolean;
  isMobile: boolean;
}>`
  order: ${(props) => (props.isMobile ? '-1' : '2')};
  .dot {
    height: 6px;
    width: 6px;
    background-color: white;
    border-radius: 50%;
  }
`;

const CollapseButtonContainer = styled.div<{ isSideNavExtended: boolean }>`
  position: absolute;
  top: ${(props) => (props.isSideNavExtended ? '40px' : '48px')};
  right: ${(props) => (props.isSideNavExtended ? '-15px' : '-17px')};
`;

const CloseContainer = styled.div`
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 0px 0px 50px 2px;
  position: absolute;
  top: 20px;
  right: 24px;
`;

const BurgerContainer = styled.div`
  color: white;
  display: flex;
  flex-direction: row;
`;

const Line = styled.hr<{ isMobile: boolean; isSideNavExtended: boolean }>`
  display: ${(props) =>
    props.isMobile && !props.isSideNavExtended ? `none` : `block`};
  border: 0.5px solid white;
  border-top: none;
  margin: ${(props) => (props.isMobile ? '5em 0' : '0em 0')};
  opacity: ${(props) => (props.isMobile ? '0.1' : '0')};
`;

const StyledLoadingSpinner = styled(LoadingIndicator)`
  width: 25px;
  margin: 10px 0px 20px 0px;
`;

export { SideNav };
